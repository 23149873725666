.axis-notification-content {

  .axis-list-group-item {
    font-size: 1rem;
    padding: 0.75rem;
    background: #F6F9FD;

    .axis-notification-title {
      font-size: 1rem;
      color: inherit;
    }

    a:not(.axis-btn) {
      font-weight: initial;
      color: var(--blue-dark);
    }

    .axis-badge[data-kind=circle] {
      span {
        font-size: 1.25rem;
        font-weight: 900;
      }
    }
  }
}