/* charts skeleton */
.axis-skeleton .axis-chart-y-axis {
  display: none;
}

.axis-skeleton .axis-chart-bar-horiz .axis-chart-label-y-text {
  background-color: #DCE2EA;
  border-radius: 0.25rem;
  color: transparent;
  opacity: 0.5;
}

.axis-chart-sankey.axis-skeleton .sankey,
.axis-skeleton .axis-chart-bar-horiz .axis-chart-values {
  filter: saturate(0);
  opacity: 0.3;
}

.axis-skeleton .axis-chart-bar-horiz .axis-chart-label-y-text:nth-child(2n+2) {
  margin-left: 50% !important;
  width: 50% !important;
}

.axis-skeleton .axis-chart-bar-horiz .axis-chart-values>div>strong {
  border-color: transparent;
}

.axis-chart-sankey.axis-skeleton canvas {
  cursor: default !important;
}

.axis-skeleton-message {
  color: var(--body-color);
  font-size: 2rem;
  opacity: 0.5;
  position: absolute;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}