/* global search wrapper */
.search-wrapper {
  padding: 0 1rem;
  transition: all .3s ease;
  height: 90px;

  &.hideSearch {
    margin-top: -90px;
  }

  .search-container {
    background-color: var(--search-bg);
    border: 1px solid var(--component-border-color);
    box-shadow: 0 12px 24px 5px rgb(0 87 184 / 6%);
    border-width: 0 1px 1px;
    padding: 1rem;
  }
}

.myaig-search-active {
  overflow: hidden;
}

.myaig-search-error,
ul.myaig-search-results {
  background: var(--component-bg);
  border: 1px solid var(--border-color);

  border-radius: 0 0 .375rem .375rem;
  border-color: rgba(147, 163, 183, .21);
  box-shadow: 0 12px 35px 0 rgb(147 163 183 / 51%);
  color: #888;
  list-style: none;
  margin-bottom: 0;
  max-height: 227px;
  /* supports line item height plus top border */
  position: absolute;
  width: 100%;
  z-index: 11;

  overflow: auto;

  opacity: 1;
  transition: .4s ease-in-out;
  transition-property: max-height, opacity;
  padding: 0 !important;
}

.myaig-search-error,
.myaig-search-results .myaig-search-list {
  display: block;
  padding: .5rem !important;

  @media (min-width: 992px) {
    padding: .5rem 1rem !important;
  }

  text-decoration: none !important;
}

.myaig-search-error {
  z-index: 9 !important;
}

.myaig-search-results .myaig-search-link {
  color: var(--blue-dark);
  display: inline;
  font-weight: 300;
  margin-left: 1rem;
  padding: 0;
}

.myaig-search-results .myaig-search-link:first-child {
  margin-left: 0.25rem;
}

.myaig-search-results .myaig-search-link .highlight {
  font-weight: 700;
}

.myaig-search-results .myaig-search-link:hover {
  color: var(--component-color);
}


.myaig-search-results li>.myaig-search-list {
  border-top: 1px solid var(--component-border-color);
}

.myaig-search-results li:first-child a {
  border: 0;
}

.myaig-search-results li:last-child a {
  margin-bottom: 0;
}

.myaig-search-results li>.myaig-search-list:focus {
  background: rgba(19, 82, 222, .1);
}

ul.myaig-search-results.axis-conceal-transition {
  max-height: 0;
  display: none;
  opacity: 0;
  transition: .4s ease-in-out;
  transition-property: max-height, opacity;
}

.myaig-search-error span {
  opacity: .58;
}

.myaig-search-wrapper {
  position: relative;
}

.myaig-search-results .axis-custom-icons {
  font-size: .875rem !important;
}

body.axis-keyboard .myaig-search-results .myaig-search-list:focus {
  outline-offset: -3px !important;
}

@media (max-width: 991px) {

  .myaig-search-wrapper ul.myaig-search-results {
    font-size: 1.2rem;
    min-width: unset;
    opacity: 1;
  }
}

/* end wrapper */

/* FAQS */
.faqs-container {
  background: var(--component-bg);
  border: 1px solid var(--component-border-color);
  box-shadow: 0 12px 24px 5px rgb(0 87 184 / 6%);
  border-width: 1px;
  padding: 1rem;
}

.faqs-container ._w-px-50 {
  min-width: auto;
}

.search-faqs ul.myaig-search-results {
  max-height: 337px;
  /* supports line item height plus top border */
}