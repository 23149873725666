/*Nested Table */

@media (min-width: 768px) {
  .nested-tr:not(.nested-tr-last) .details-row {
    padding-bottom: 0 !important;
  }

  .nested-content {
    display: flex;
    margin-left: 16px;

    .detail:first-child {

      border-left: 1px solid var(--border-color);
      flex-basis: 2%;
      background: inherit;
      margin-top: 0;
      position: relative;

      //transform: translate(0,14px);/* font size of the detail row */
      &:before {
        content: '';
        border-top: 1px solid var(--border-color);
        position: absolute;
        width: 8px;
        left: 0;
        top: 50%;
        transform: translate(0, -50%);
      }
    }
  }

  .detail {
    margin: 0;
    padding: 4px 8px 4px 12px;
    flex-grow: 1;
    flex-basis: 100%;
  }

}