._custom-star {
  font-size: 1.5rem;
  line-height: 1;
  margin-left: 15px;
}

._custom-favorite {
  &:hover {
    .material-icons {
      color: #ffbf3f !important;
    }
  }

  .material-icons {
    font-size: 2rem !important;
    color: #b1b1b1 !important;
  }

  .axis-icons[data-color=warning] {
    .material-icons {
      color: #ffbf3f !important;
    }
  }
}

.axis-navbar-header .axis-navbar-item .axis-switch .axis-switch-label:before {
  background: #93A3B7;
}

// Fav Model on login
.card-fav {
  background: #fff;
  box-shadow: 0 4px 8px #0057b810;
  cursor: pointer;
  text-align: center;
  transition: background .3s ease;
  transition: all .8s ease;
  width: 9rem;

  .axis-card-body {
    padding: 1rem 0.5rem 0 !important;
    align-items: stretch;

    .axis-icons {
      display: block;
      margin-bottom: 8px;
      transition: all .8s ease;
    }

    a {
      display: block;
      font-size: 1rem;
      font-weight: 600;
      text-decoration: none;
    }
  }

  .axis-card-footer {
    padding: 0.5rem 0 !important;
    height: 2.5rem;
  }

  &:hover {
    border-color: #00a4e4;
    background: #1352de;
    transform: scale(1.05);

    .active,
    .axis-custom-icons,
    a {
      color: #fff !important;
    }
  }
}
