.axis-progress-step[data-kind=secondary] {
  .axis-progress-item {


    .axis-progress-status {
      margin-left: 0.5rem;
      margin-top: 0.25rem;
    }

    .axis-card .axis-progress-status {
      margin-bottom: 1rem;
      padding: 0;
    }
  }
}