.shrink-sticky {

  .axis-card,
  .axis-card-row,
  .axis-control-label,
  .axis-form-control,
  .axis-dropdown .axis-dropdown-toggle,
  .axis-input-group {
    transition: all .3s ease;
  }
}

.shrink-container {
  .axis-card {
    background: var(--secondary);
  }

  .axis-grid {
    grid-row-gap: 1em;
  }

  .axis-control-label {
    font-size: .875rem;
    margin-bottom: .1rem
  }

  .axis-card-row {
    padding-top: .5rem !important;
  }

  .axis-form-control,
  .axis-dropdown .axis-dropdown-toggle {
    @extend %form-control-small;
  }

  .axis-input-group {
    @extend %input-group-small;
  }

  .axis-btn[data-kind="primary"] {
    height: 40px;
  }

  .axis-btn[data-kind="link"] {
    min-height: unset !important;
  }
}