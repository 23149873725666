.loss-run-container {
  .preset-container {
    background: var(--custom-bg);
    padding: 1.5rem;
    flex: 1;
  }

  .search-container {
    background: var(--custom-bg);
    padding: 1.5rem;
  }
}

.loss-run-or-wrapper {
  height: 100%;

  @media (max-width: 767px) {
    text-align: center;
    overflow: hidden;
  }
}

.loss-run-or {
  color: var(--primary);
  font-weight: 600;
  font-size: 1.5rem;
  padding: 2rem;

  display: inline-block;
  position: relative;

  @media (min-width: 768px) {
    padding-top: 3.8rem;
    height: 100%;

  }

  &:before,
  &:after {
    position: absolute;
    content: "";

    @media (max-width: 767px) {
      background: var(--primary);
      height: 1px;
      width: 9999px;
      top: 50%;
    }

    @media (min-width: 768px) {
      width: 1px;
      left: 50%;
      border-left: 1px solid var(--primary);
    }
  }

  &:before {
    @media (max-width: 767px) {
      right: 100%;
    }

    @media (min-width: 768px) {
      top: 0;
      height: 50px;
    }
  }

  &:after {
    @media (max-width: 767px) {
      left: 100%;
    }

    @media (min-width: 768px) {
      top: 100px;
      bottom: 0;
    }
  }
}