.axis-custom-header {
  background-color: var(--body-bg);
  background-image: url(../assets/images/bg/hero-pattern.svg);
  background-position: center;
  background-repeat: no-repeat;
  border-bottom: 1px solid var(--component-border-color);
  margin-bottom: 0;
  padding: 1.5rem 1rem;

  @media (min-width: 768px) {
    background-size: cover;
  }

  .axis-chart-donut-value,
  .axis-chart-donut-label,
  h3 {
    color: inherit;
  }

  h1 {
    color: inherit;
    font-weight: 300;
    margin-bottom: 2rem;
  }

  .axis-summary-grid {
    padding-right: 3rem;

    .axis-summary-text {
      margin: 0;
      font-weight: 400;
    }

    &[data-separator=true]>[class*=axis-col]:not(:last-child) {
      border-right: 2px solid rgba(224, 229, 235, 0.15);
    }
  }

  .custom-header-action {
    background-color: rgba(0, 24, 113, 0.85);
    border-radius: 6px;
    color: #fff;
    padding: 2rem;

    h2 {
      color: #fff;
      font-weight: 300;
    }
  }

}

// header dark background icons
.axis-custom-header .axis-icons[data-size=xxsmall] .material-icons,
.axis-custom-header .axis-icons[data-size=xxsmall] .axis-custom-icons {
  font-weight: 900;
}

.axis-custom-page-container {
  margin-top: -43px;
}

@media (min-width: 768px) {
  .axis-navbar-item .btn-bg {
    z-index: 1;

    &:after {
      background: transparent;
      border-radius: 50%;
      content: '';
      position: absolute;
      top: 50%;
      transform: translate(-50%, -50%);
      width: 40px;
      height: 40px;

      @media (min-width: 992px) {
        width: 65px;
        height: 65px;
      }

      left: 50%;
      z-index: -1;
      transition: background .3s ease;
    }
  }

  .axis-navbar-item.active .btn-bg {
    &:after {
      background: var(--secondary);
    }

    &:before {
      border: none !important;
    }
  }

  .axis-sticky-body .axis-navbar-shrink .axis-navbar-item .btn-bg {
    &:after {
      @media (min-width: 992px) {
        width: 50px;
        height: 50px;
      }
    }
  }
}

.custom-card-wrapper {
  box-shadow: 0 12px 24px 5px rgb(0 87 184 / 6%);

  .custom-card-title {
    font-size: 1.125rem;
    font-weight: 400;
  }

  .custom-card {
    background: #fff;
    box-shadow: 0px 4px 8px rgba(0, 87, 184, 0.0625566);
    font-size: 14px;
    transition: background 0.3s ease;
    transition: all 0.8s ease;


    @media (min-width: 768px) {
      min-height: 123px;
      width: 140px;
      text-align: center;
    }


    // transition: background 0.3s ease;


    .axis-card-body {
      padding: .5rem;
    }

    .axis-icons {
      margin-right: 1rem;
      transition: all 0.8s ease;

      @media (min-width: 768px) {
        display: block;
        margin-bottom: 8px;
        margin-right: 0;
        margin-top: 1rem;
      }

    }

    a {
      font-size: 1rem;
      font-weight: 600;
      text-decoration: none;
    }

    &:hover {
      background: #1352de;
      transform: scale(1.05);


      .axis-custom-icons,
      a {
        color: #fff !important;
      }

      .axis-label {
        background-color: #fff !important;
        color: #1352DE !important;
      }
    }
  }
}