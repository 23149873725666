// Import Axis Base SCSS
@import "node_modules/axis-components/styles/theming/external/main";
@import "node_modules/axis-components/styles/components/tour/source";

// Add custom SCSS below
@import "alert-global";
@import "favorite";
@import "header";
@import "login";
@import "loss-run";
@import "notification";
@import "okta";
@import "progress-step";
@import "search";
@import "skeleton";
@import "sticky";
@import "table";
@import "tour";

.axis-right-sidebar {
  top: 0;
}

.cdk-virtual-scroll-viewport {
  @extend .scroll
}

.sidebar-alert .axis-notification-visible {
  right: 0.5rem;
  top: 5.5rem;
}

.axis-menu-custom {
  @media (max-width: 767px) {
    --headings-color: #fff;
  }

  @media (min-width: 768px) {
    color: var(--body-color);
  }
}

:root {
  --custom-bg: rgba(255, 255, 255, 0.6);
  --notification-bg: #F6F9FD;
  --search-bg: #DCE2EA;

  &.axis-dark {
    --notification-bg: #38393f;
    --custom-bg: rgba(255, 255, 255, 0.1);
    --search-bg: #38393c;
  }
}

$screen-md-min: 768px;
$screen-lg-min: 992px;

@media (min-width: 768px) {
  .axis-datagrid-head ._hidden-table-header-md-up span {
    display: none;
  }
}

._custom-margin .axis-form-group {
  margin-bottom: 0;
}

.analytics-tools-custom-scroll {
  @extend .scroll;
  overflow-y: auto;
  max-height: 553px;
}
