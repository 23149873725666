#sign-in-widget {
  animation: fadein 1s linear;
}

@keyframes fadein {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }

}

.custom-wrapper {
  min-height: 610px;
  padding: 0 1.25rem;
}
