#okta-sign-in {
  font-family: "aig_sourcesanspro", Arial, Helvetica, sans-serif !important;

  input {
    color: #343741 !important;
    font-family: "aig_sourcesanspro", Arial, Helvetica, sans-serif !important;
    font-size: 16px !important;
  }

  &.auth-container.main-container {
    width: auto;
    border-radius: 6px;
    border-color: #e0e5eb;
    box-shadow: 0 12px 24px 5px rgb(0 87 184 / 6%);
  }

  &.auth-container {
    margin-top: 8px;

    .auth-content {
      max-width: unset;
    }
  }

  .o-form {
    .o-form-fieldset {
      margin-bottom: 24px !important;
    }

    .o-form-fieldset:last-child {
      margin-bottom: 0px !important;
    }

    label {
      font-size: 16px !important;
      color: #343741 !important;
      margin-bottom: 4px !important;
    }

    .input-fix,
    .textarea-fix {
      height: 56px !important;
      border-color: #b9c6d4 !important;
    }

    h2 {
      font-weight: 600 !important;
      font-size: 20px !important;
      color: #001871 !important;
    }

    .button-primary {
      border-radius: 30px !important;
      color: #ffffff !important;
      font-size: 16px !important;
      font-weight: bold !important;
    }

    .custom-checkbox label {
      font-size: 14px !important;
      color: #343741 !important;
    }
  }

  .auth-content .link,
  .registration-link {
    color: #1352de !important;
  }

  .auth-footer {
    font-size: 14px !important;
  }

  .sms-request-button {
    background: #1352ef !important;
    border-color: #1352ef !important;
    border-radius: 30px !important;
    color: white !important;
    font-family: "aig_sourcesanspro", Arial, Helvetica, sans-serif !important;
    font-size: 16px !important;
    font-weight: bold !important;
    height: 56px !important;
    line-height: 50px !important;
    padding: 0 16px !important;
    white-space: nowrap !important;
    width: auto !important;
  }

  .sms-request-button.disabled {
    color: #5e5e5e !important;
    background-color: #fbfbfb !important;
    border-color: #c3c3c3 #c3c3c3 #bbb !important;
  }

  .infobox {
    border-color: #b9c6d4 !important;
    font-size: 14px !important;
    color: #343741 !important;

    &::before {
      background-color: #1352DE !important;
    }
  }

  .infobox-error {
    border-color: #b9c6d4 !important;
    font-size: 14px !important;
    color: #343741 !important;

    &::before {
      background-color: #E81944 !important;
    }
  }

  .o-form-input-error {
    color: #E81944;
  }
}